@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');


.nav-link {
    font-weight: 800;
    font-size: 1.2rem;
    color: white;
}


.nav-link:hover {
    font-weight: 800;
    color: #ff8800;
    cursor: pointer;
}

.NavbarBrand {
    font-family: 'Dancing Script', cursive;
    font-weight:800;
    color: white;
    font-size: 48px;
}

.hamburger {
    display: none;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}



@media screen and (max-width: 1200px) {
    .NavbarBrand {
        font-size: 36px;
    }

    .nav-link {
        display: none;
    }

    .hamburger {
        display: block;
        color: white;
    }

    .hamburger:hover {
        cursor: pointer;
        color: #ff8800;
    }



}