h1 {
    padding: 20px;
}

.cardgroup {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1200px) {
    .center h1 {
        font-size: 1.4rem;
        padding: 5px;
        margin: 5px;
    }

    .center div {
        font-size: 12px;
    }
    .center {
        margin: 20px;
        padding: 15px;
        margin: 12px;
    }

    .cardgroup {
        padding: 10px;
    }
}