.social-media-links {
    padding: 10px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9998;
}

.social-media-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F9C4FF;
    color: white;
    transition: background-color 0.3s ease;
}

.social-media-links div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F9C4FF;
    color: white;
    transition: background-color 0.3s ease;
}

.email-link {
    background-color: #FFC4C4;
    color: white;
}

.social-media-links a:hover {
    background-color: #BA05FC;
}

.social-media-links div:hover {
    background-color: #BA05FC;
}

@media screen and (max-width: 1200px) {
    .social-media-links {
        right: 0; /* Positioniert die Links auf der rechten Seite */
        bottom: 0;
        top: auto;
        transform: none;
        flex-direction: column;
        justify-content: center;
    }
}