@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.slogan {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Dancing Script', cursive;
    font-weight:800;
    color: Black;
    font-size: 50px;
}

.center-me
{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 50px;
    border-radius: 15px;
    background-color: rgba(253, 237, 255, 0.63);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    font-size: 24px;
}

.separator
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row .element1 {
    width: 60%;
}

.row .element2 {
    width: 40%;
}

.melissaRosa {
    border-radius: 50px;
}

@media screen and (max-width: 1200px) {
    .slogan {
        font-size: 24px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .center-me h1 {
        font-size: 1.4rem;
        padding: 5px;
        margin: 5px;
    }

    .center-me div {
        font-size: 12px;
    }

    .center-me {
        padding: 15px;
        margin: 10px;
    }

    .row {
        flex-direction: column;
    }

    .row .element1 {
        width: 100%;
    }

    .row .element2 {
        width: 80%;
    }

    .melissaRosa {
        border-radius: 40px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}