.full-width-carousel-images {
    width: 100%;
}

.hide-caption .carousel-caption {
    display: none;
}

@media screen and (max-width: 1200px) {
    .full-width-carousel-images {
        width: 100%;
    }

    .carousel {
        width: 100%;
    }


}