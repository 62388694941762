.threeSections {
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.opening-hours {
     border: 1px solid #000;
     padding: 20px;
     border-radius: 10px;
     background-color: #fff;
     color: #808080;
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: flex-start;
     flex-direction: column;
 }
.opening-hours h5 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.opening-hours div {
    margin-top: 0px;
    margin-bottom: 15px;
}
.more-button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    background: linear-gradient(to right, rgba(227, 22, 254, 0.5), rgba(135, 123, 254, 0.5), rgba(92, 243, 254, 0.4));
    cursor: pointer;
    width: 50%;
    color: black;
}
.gallery-button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    background: linear-gradient(to right, rgba(227, 22, 254, 0.5), rgba(135, 123, 254, 0.5), rgba(92, 243, 254, 0.4));
    cursor: pointer;
    width: 50%;
    color: black;
}





@media screen and (max-width: 1200px) {
    .threeSections {
        flex-direction: column;
    }
    .column {
        padding: 10px;
    }
    .opening-hours {
        padding: 10px;
    }
    .opening-hours h5 {
        font-size: 1.4rem;
    }
    .opening-hours div {
        font-size: 1.2rem;
    }
    .more-button {
        padding: 5px 5px;
        margin-top: 10px;
        font-size: 15px;
        width: 100%;
        height: 40px;
    }
    .gallery-button {
        padding: 5px 5px;
        margin-top: 10px;
        font-size: 15px;
        width: 100%;
        height: 40px;
    }
    .center h1 {
        font-size: 1.4rem;
        padding: 5px;
        margin: 5px;
    }

    .center div {
        font-size: 12px;
    }

    .opening-hours {
        padding: 10px;
        width: 120%;
        background-color: rgba(253, 237, 255, 0.41);
    }
    .opening-hours h5 {
        font-size: 0.8rem;
    }
    .opening-hours div {
        font-size: 0.8rem;
    }

}
