.address {
    font-weight: 800;
    font-size: 1rem;
    color: white;
}

@media screen and (max-width: 1200px) {
    .address {
        font-size: 0.7rem;
    }
}