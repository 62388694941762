.center {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(253, 237, 255, 0.63);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}



.center:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
}

.booking-button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    background: linear-gradient(to right, rgba(227, 22, 254, 0.5), rgba(135, 123, 254, 0.5), rgba(92, 243, 254, 0.4));
    cursor: pointer;
    width: 50%;
    color: black;
}

.booking-button:hover {
    background: linear-gradient(to right, rgb(227, 22, 254), rgb(135, 123, 254), rgb(92, 243, 254));
    color: white;
}

@media screen and (max-width: 1200px) {
    .center h1 {
        font-size: 1.4rem;
        padding: 5px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .center div {
        font-size: 12px;
    }
    .center {
        margin: 20px;
        padding: 15px;
        margin: 12px;
    }

    .booking-button {
        padding: 5px 5px;
        margin-top: 10px;
        font-size: 15px;
        width: 90%;
        height: 40px;
    }

}