.sidebar {
    position: fixed;
    left: -250px;
    width: 250px;
    height: 100%;
    background: #c931fc;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 16px;
    padding: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: left 0.5s ease;
    z-index: 9999;
}

.sidebar.open {
    left: 0;
}

.sidebar-link {
    display: block;
    padding: 10px;
    margin: 0px 0;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #78a0fc;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.5s ease;
}

.sidebar-link:hover {
    background-color: #5CF3FE;
}