@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');


body {
    background-color: rgba(176, 172, 255, 0.39);
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

#page {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.page-transition {
  opacity: 0;
}